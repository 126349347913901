html, body {
  height: 100%; 
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

body {
  font-family: 'Inter', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: hidden;
  /*background-color: #0F1C4D !important;*/
  /*background-color: white !important;*/
}

.no-select {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

/* Keyframe animations */
@keyframes fadeInUp {
  from {
      transform: translateY(20px);
      opacity: 0;
  }
  to {
      transform: translateY(0);
      opacity: 1;
  }
}

@keyframes fadeIn {
  to {
      opacity: 1;
  }
}

@keyframes fadeInLeft {
  from {
      opacity: 0;
      transform: translateX(-50px);
  }
  to {
      opacity: 1;
      transform: translateX(0);
  }
}

@keyframes fadeInRight {
  from {
      opacity: 0;
      transform: translateX(50px);
  }
  to {
      opacity: 1;
      transform: translateX(0);
  }
}

@keyframes fadeInUp {
  from {
      opacity: 0;
      transform: translateY(50px);
  }
  to {
      opacity: 1;
      transform: translateY(0);
  }
}

.start-fade-animation {
  animation: fadeInUp 0.5s ease forwards;
}

.fade-enter {
  opacity: 0;
}
.fade-enter-active {
  opacity: 1;
  transition: opacity 0.6s ease-in-out;
}
.fade-exit {
  opacity: 1;
}
.fade-exit-active {
  opacity: 0;
  transition: opacity 0.6s ease-in-out;
}

.slide-enter {
  transform: translateY(50px);
  opacity: 0;
}
.slide-enter-active {
  transform: translateY(0);
  opacity: 1;
  transition: transform 0.8s ease, opacity 0.8s ease;
}
.slide-exit {
  transform: translateY(0);
  opacity: 1;
}
.slide-exit-active {
  transform: translateY(50px);
  opacity: 0;
  transition: transform 0.8s ease, opacity 0.8s ease;
}
