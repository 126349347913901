.page-container {
    min-height: calc(100 * var(--vh) - 70px);
    background-color: white;
}

.page-container-full {
    min-height: calc(100 * var(--vh) - 70px);
    background-color: white;
}

#detail {
    padding: 0;
}

#content-area {
    overflow-y: auto;
    overflow-x: hidden;
    height: calc(100 * var(--vh) - 70px);
}

#content-area-full-page {
    overflow-y: auto;
    overflow-x: hidden;
    height: 100vh;
}

.page-container .copyright {
    position: absolute;
    color: #75767b;
    font-size: 12px;
    text-align: center;
    bottom: 0;
}

.page-container .white-card,
.page-container-full .white-card {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    background-color: white;
    padding: 24px;
    border-radius: 8px;
}

.page-container .white-card-with-border,
.page-container-full .white-card-with-border {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    background-color: white;
    padding: 24px;
    border-radius: 8px;
    border: 1px solid #dfe3ea;
}

.custom-input-field textarea {
    flex: 1;
}

@media (min-width: 992px) {
    #detail {
        margin-left: 250px;
        flex-grow: 1;
    }
}

@media (max-width: 993px) {
    .page-container {
        min-height: calc(100 * var(--vh) - 61px);
    }

    #content-area {
        min-height: calc(100 * var(--vh) - 61px);
    }
}
