/* FUNNEL DISPLAY */
@font-face {
    font-family: 'Funnel Display';
    src: url('/public/fonts/Funnel_Display/FunnelDisplay-VariableFont_wght.ttf') format('truetype');
    font-weight: 300 800; /* Variable font axis */
    font-style: normal;
}

/* FUNNEL SANS */
@font-face {
    font-family: 'Funnel Sans';
    src: url('/public/fonts/Funnel_Sans/FunnelSans-VariableFont_wght.ttf') format('truetype');
    font-weight: 300 800; /* Variable font axis */
    font-style: normal;
}

@font-face {
    font-family: 'Funnel Sans';
    src: url('/public/fonts/Funnel_Sans/FunnelSans-Italic-VariableFont_wght.ttf') format('truetype');
    font-weight: 300 800; /* Variable font axis */
    font-style: italic;
}

/* MANROPE */
@font-face {
    font-family: 'Manrope';
    src: url('/public/fonts/Manrope/Manrope-VariableFont_wght.ttf') format('truetype');
    font-weight: 200 800; /* Variable font axis */
    font-style: normal;
}

/* SPACE GROTESK */
@font-face {
    font-family: 'Space Grotesk';
    src: url('/public/fonts/Space_Grotesk/SpaceGrotesk-VariableFont_wght.ttf') format('truetype');
    font-weight: 300 700; /* Variable font axis */
    font-style: normal;
}

/* SPACE MONO */
@font-face {
    font-family: 'Space Mono';
    src: url('/public/fonts/Space_Mono/SpaceMono-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Space Mono';
    src: url('/public/fonts/Space_Mono/SpaceMono-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Space Mono';
    src: url('/public/fonts/Space_Mono/SpaceMono-Italic.ttf') format('truetype');
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: 'Space Mono';
    src: url('/public/fonts/Space_Mono/SpaceMono-BoldItalic.ttf') format('truetype');
    font-weight: 700;
    font-style: italic;
}
